export default {
    // private
    dashboard: '/dashboard',
    rules_editor: '/rules',
    rules_diff: '/td/diff/rules',
    agl: '/agl/dashboard',
    agl_rules: '/agl/rules/:version',
    agl_rules_edit: '/agl/rules/:version_id/edit',
    agl_rules_diff: '/agl/diff/rules',
    rules_validator: '/rules-validator/video-url',
    rules_validator_sc_api: '/rules-validator/sc-api',
    agl_server_status: '/agl/server-status',
    
    // TLB Config
    tlb_route_config:'/tlb/routing/dashboard',
    tlb_shape_config:'/tlb/shaping/dashboard',
    tlb_app_config:'/tlb/rules-validator/app-config',
    tlb_rules_diff: '/tlb/diff/rules',
    tlb_rules_editor: '/tlb/new/rules',
    tlb_shaping_rules_diff: '/tlb/shaping/diff/rules',
    tlbs_rules_editor: '/tlb/shaping/new/rules',
    tlb_shape_rv:'/tlb/shaping/rules-validator',
    routing_tlb_api_status: '/tlb/routing/config-api-status',
    shaping_tlb_api_status: '/tlb/shaping/config-api-status',

    //td-shorts
    shorts_dashboard: '/td/shorts-ui/dashboard',
    shorts_rules_editor: '/td/shorts-ui/rules',
    shorts_rules_diff: '/td/shorts-ui/diff/rules',
    shorts_rules_validator_sc_api: '/td/shorts-ui/rules-validator/sc-api',
    shorts_config_api_status: '/td/shorts-ui/config-api-status',

    td_config_api_status: '/td/config-api-status',
    config_api_status: '/agl/config-api-status',
    // auth
    login: '/login',
    callback: '/callback',
    signup: '/signup',
    forgotPassword: '/forgot_password',

    //App Config - Initial Config
    appconfig:'/appconfig/initial_config/dashboard',
    app_config_api_status: '/appconfig/initial_config/config-api-status',
    app_config_rules_diff: '/appconfig/initial_config/diff/rules',
    app_config_rules_editor: '/appconfig/initial_config/rules',
    app_config_rules_bulk_update: '/appconfig/initial_config/bulkupdate',


    //App Config - Base Initial Config
    base_appconfig:'/appconfig/base_initial_config/dashboard',
    base_app_config_api_status: '/appconfig/base_initial_config/config-api-status',
    base_app_config_rules_diff: '/appconfig/base_initial_config/diff/rules',
    base_app_config_rules_editor: '/appconfig/base_initial_config/rules',
    base_app_config_rules_bulk_update: '/appconfig/initial_config/bulkupdate',


    //App Config - Dictionary Config
    dictionary:'/appconfig/dictionary/dashboard',
    dictionary_config_api_status: '/appconfig/dictionary/config-api-status',
    dictionary_config_rules_diff: '/appconfig/dictionary/diff/rules',
    dictionary_config_rules_editor: '/appconfig/dictionary/rules',
    dictionary_config_rules_bulk_update: '/appconfig/dictionary/bulkupdate',


    //App Config - Base Dictionary Config
    base_dictionary:'/appconfig/base_dictionary/dashboard',
    base_dictionary_config_api_status: '/appconfig/base_dictionary/config-api-status',
    base_dictionary_config_rules_diff: '/appconfig/base_dictionary/diff/rules',
    base_dictionary_config_rules_editor: '/appconfig/base_dictionary/rules',
    base_dictionary_config_rules_bulk_update: '/appconfig/base_dictionary/bulkupdate',


    //Object Repo
    app_config_object_repo: '/appconfig/object',
    app_config_create_object: '/appconfig/object/create',

    criteria_object_repo:'/appconfig/criteria',

    //App Config - AGL_SMART_HOOK Config
    AGL_SMART_HOOK:'/appconfig/AGL_SMART_HOOK/dashboard',
    AGL_SMART_HOOK_config_api_status: '/appconfig/AGL_SMART_HOOK/config-api-status',
    AGL_SMART_HOOK_config_rules_diff: '/appconfig/AGL_SMART_HOOK/diff/rules',
    AGL_SMART_HOOK_config_rules_editor: '/appconfig/AGL_SMART_HOOK/rules',
    AGL_SMART_HOOK_config_rules_bulk_update: '/appconfig/AGL_SMART_HOOK/bulkupdate',


    //App Config - AGL_POPULAR_CATEGORY Config
    AGL_POPULAR_CATEGORY:'/appconfig/AGL_POPULAR_CATEGORY/dashboard',
    AGL_POPULAR_CATEGORY_config_api_status: '/appconfig/AGL_POPULAR_CATEGORY/config-api-status',
    AGL_POPULAR_CATEGORY_config_rules_diff: '/appconfig/AGL_POPULAR_CATEGORY/diff/rules',
    AGL_POPULAR_CATEGORY_config_rules_editor: '/appconfig/AGL_POPULAR_CATEGORY/rules',
    AGL_POPULAR_CATEGORY_config_rules_bulk_update: '/appconfig/AGL_POPULAR_CATEGORY/bulkupdate',


    //App Config - AGL_REPORT_ISSUE Config
    AGL_REPORT_ISSUE:'/appconfig/AGL_REPORT_ISSUE/dashboard',
    AGL_REPORT_ISSUE_config_api_status: '/appconfig/AGL_REPORT_ISSUE/config-api-status',
    AGL_REPORT_ISSUE_config_rules_diff: '/appconfig/AGL_REPORT_ISSUE/diff/rules',
    AGL_REPORT_ISSUE_config_rules_editor: '/appconfig/AGL_REPORT_ISSUE/rules',
    AGL_REPORT_ISSUE_config_rules_bulk_update: '/appconfig/AGL_REPORT_ISSUE/bulkupdate',


    //App Config - AGL_FEATURE_CONFIG Config
    AGL_FEATURE_CONFIG:'/appconfig/AGL_FEATURE_CONFIG/dashboard',
    AGL_FEATURE_CONFIG_config_api_status: '/appconfig/AGL_FEATURE_CONFIG/config-api-status',
    AGL_FEATURE_CONFIG_config_rules_diff: '/appconfig/AGL_FEATURE_CONFIG/diff/rules',
    AGL_FEATURE_CONFIG_config_rules_editor: '/appconfig/AGL_FEATURE_CONFIG/rules',
    AGL_FEATURE_CONFIG_config_rules_bulk_update: '/appconfig/AGL_FEATURE_CONFIG/bulkupdate',


    //App Config - SHORTS_REPORT_ISSUE Config
    SHORTS_REPORT_ISSUE:'/appconfig/SHORTS_REPORT_ISSUE/dashboard',
    SHORTS_REPORT_ISSUE_config_api_status: '/appconfig/SHORTS_REPORT_ISSUE/config-api-status',
    SHORTS_REPORT_ISSUE_config_rules_diff: '/appconfig/SHORTS_REPORT_ISSUE/diff/rules',
    SHORTS_REPORT_ISSUE_config_rules_editor: '/appconfig/SHORTS_REPORT_ISSUE/rules',
    SHORTS_REPORT_ISSUE_config_rules_bulk_update: '/appconfig/SHORTS_REPORT_ISSUE/bulkupdate',


    //App Config - CUSTOMERCARECONFIG Config
    CUSTOMERCARECONFIG:'/appconfig/CUSTOMERCARECONFIG/dashboard',
    CUSTOMERCARECONFIG_config_api_status: '/appconfig/CUSTOMERCARECONFIG/config-api-status',
    CUSTOMERCARECONFIG_config_rules_diff: '/appconfig/CUSTOMERCARECONFIG/diff/rules',
    CUSTOMERCARECONFIG_config_rules_editor: '/appconfig/CUSTOMERCARECONFIG/rules',
    CUSTOMERCARECONFIG_config_rules_bulk_update: '/appconfig/CUSTOMERCARECONFIG/bulkupdate',


    //App Config - AGL_BRANDING Config
    AGL_BRANDING:'/appconfig/AGL_BRANDING/dashboard',
    AGL_BRANDING_config_api_status: '/appconfig/AGL_BRANDING/config-api-status',
    AGL_BRANDING_config_rules_diff: '/appconfig/AGL_BRANDING/diff/rules',
    AGL_BRANDING_config_rules_editor: '/appconfig/AGL_BRANDING/rules',
    AGL_BRANDING_config_rules_bulk_update: '/appconfig/AGL_BRANDING/bulkupdate',

};
