import { toast } from 'react-toastify';
import { fetchUtil } from '../../api/ApiUtils.js';
import { configAccess } from 'utils.js';

const { REACT_APP_API_URL } = process.env;
const tlb_version = localStorage.getItem('tlb_version');

export const getAllTlbItems = (version) => {
    return async (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
        await fetchUtil(
            REACT_APP_API_URL+"/tlb-config/routing/config?version="+version, { method: "GET" },
            (data) => {
            dispatch({ type: "GET_TLB_ITEMS", rulesData: data })
            },
            () => {
                
            }
        );                
    };
};

export const getAllShapingItems = (version) => {
    return async (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
        await fetchUtil(
            REACT_APP_API_URL+"/tlb-config/shaping/config?tlbs_version="+version, { method: "GET" },
            (data) => {
            dispatch({ type: "GET_SHAPING_ITEMS", rulesData: data })
            },
            () => {
                
            }
        );                
    };
};

export const updateTlbItems = (data,itemType,isMadeChanges=true,notes='') =>{
    return async (dispatch) => {
        dispatch({ type: "UPDATE_TLB_ITEM", rulesData: data,itemType:itemType, isMadeChanges:isMadeChanges,notes:notes })
    }
};

export const updateShapingItems = (data,itemType,isMadeChanges=true,notes='') =>{
    return async (dispatch) => {
        dispatch({ type: "UPDATE_SHAPING_ITEM", rulesData: data,itemType:itemType, isMadeChanges:isMadeChanges,notes:notes })
    }
};

export const newTlbRule = (newConfig = '') =>{
    return async (dispatch)=>{
        dispatch({type:"ADD_TLB_RULE",newConfig:newConfig})
    }
};

export const newShapingRule = (newConfig = '', defaultConfig = {}) =>{
    return async (dispatch)=>{
        dispatch({type:"ADD_SHAPING_RULE",newConfig:newConfig, defaultConfig})
    }
};

export const shapingSegmentRouteRule = (newConfig = {}) =>{
    return async (dispatch)=>{
        dispatch({type:"SHAPING_SEGMENT_ROUTE",newConfig:newConfig})
    }
};

export const getIsCollapse = (isCollapse) => {
    return async(dispatch) => {
        dispatch({type:"IS_COLLAPSE", payload: isCollapse})
    }
};

export const getTlbEnvironments = (tlb_version_passed=tlb_version) =>{
    return (dispatch) => {
     fetchUtil(
           REACT_APP_API_URL+"/tlb-config/routing/active_versions?tlb_version="+tlb_version_passed, { method: "GET" },
           (data) => {
             dispatch({ type: "TLBENVIRONMENTS", payload: data })
           },
           () => {
               
           }
       );
   };
};

export const getTlbConfig = (tlb_version_passed=tlb_version) => {
    return (dispatch) => {        
        fetchUtil(
            REACT_APP_API_URL+"/tlb-config/app/config?tlb_version="+tlb_version_passed, { method: "GET" },
            (data) => {
            dispatch({ type: "CONFIG_TLB_DATA", configData: data })
            },
            () => {
                
            }
        );
                
    };
};

export const configTlbAction =  (type,version,message) =>{
    return (dispatch) => {
        let url = `${REACT_APP_API_URL}/tlb-config/routing/`+version+`/`+type;
        const tlb_version = localStorage.getItem('tlb_version');
        fetchUtil(
            url, { method: "POST", headers: {'Content-Type': 'application/json'} },
            () => {
                dispatch(getTlbConfig(tlb_version));
                dispatch(getTlbEnvironments(tlb_version));
                dispatch(getAllTlbItems(version));
                toast.success(message, {
                    theme: "colored"
                  });
            },
            () => {
                toast.error('Something went wrong!', {
                    theme: "colored"
                  })
            }
        );
    }
};

export const configShapingAction =  (type,version,message) =>{
    return (dispatch) => {
        let url = `${REACT_APP_API_URL}/tlb-config/shaping/`+version+`/`+type;
        const tlb_version = localStorage.getItem('tlb_version');
        fetchUtil(
            url, { method: "POST", headers: {'Content-Type': 'application/json'} },
            () => {
                dispatch(getTlbConfig(tlb_version));
                dispatch(getTLBShapingActiveVersions(tlb_version));
                dispatch(getAllShapingItems(version));
                toast.success(message, {
                    theme: "colored"
                  });
            },
            () => {
                toast.error('Something went wrong!', {
                    theme: "colored"
                  })
            }
        );
    }
};

export const activateTlbVersion = (env,version,message) =>{
    return (dispatch) => {
        let url = `${REACT_APP_API_URL}/tlb-config/routing/config/activate`;
        const tlb_version = localStorage.getItem('tlb_version');
        let data = {'version':version,'env':env.toLowerCase(),'tlb_version':tlb_version};
        fetchUtil(           
            url, { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) },
            (data) => {
                dispatch(getTlbConfig(tlb_version));
                dispatch(getTlbEnvironments(tlb_version));
                dispatch({ type: "IS_ACTIVATED_VERSION" });
                toast.success(message, {
                    theme: "colored"
                  });
            },
            (response, data) => {
                toast.error(data.detail, {
                    theme: "colored"
                  })
            }
        );
    }
};

export const activateShapingVersion = (env,version,message) =>{
    return (dispatch) => {
        let url = `${REACT_APP_API_URL}/tlb-config/shaping/config/activate`;
        const tlb_version = localStorage.getItem('tlb_version');
        let data = {'version':version,'env':env.toLowerCase(),'tlb_version':tlb_version};
        fetchUtil(           
            url, { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) },
            (data) => {
                dispatch(getTlbConfig(tlb_version));
                dispatch(getTLBShapingActiveVersions(tlb_version));
                dispatch({ type: "IS_ACTIVATED_VERSION" });
                toast.success(message, {
                    theme: "colored"
                  });
            },
            (response, data) => {
                toast.error(data.detail, {
                    theme: "colored"
                  })
            }
        );
    }
};

export const getActiveTLBVerions = () => {
    return (dispatch) => {        
        fetchUtil(
            REACT_APP_API_URL+"/tlb-config/active_tlb_verions", { method: "GET" },
            (data) => {
                if(configAccess('tlb_routing_access') || configAccess('tlb_shaping_access')) {
                    var tlb_version = data?.slice(-1);
                    if(tlb_version) localStorage.setItem('tlb_version',tlb_version);
                }
                dispatch({ type: "ACTIVE_TLB_VERSIONS", payload: data })
            },
            () => {
                
            }
        );
                
    };
};

export const getTLBShapingActiveVersions = (tlb_version_passed=tlb_version) =>{
    return (dispatch) => {
     fetchUtil(
           REACT_APP_API_URL+"/tlb-config/shaping/active_versions?tlb_version="+tlb_version_passed, { method: "GET" },
           (data) => {
             dispatch({ type: "TLB_SHAPING_AV", payload: data })
           },
           () => {
               
           }
       );
   };
};

export const getRoutingAlias = (tlb_version_passed=tlb_version) =>{
    return (dispatch) => {
     fetchUtil(
           REACT_APP_API_URL+"/tlb-config/routing/routing-alias?tlb_version="+tlb_version_passed, { method: "GET" },
           (data) => {
             dispatch({ type: "TLB_ROUTING_ALIAS", payload: data })
           },
           () => {
               
           }
       );
   };
};

export const updateTlbConfig = (data) =>{
    return async (dispatch) => {
        dispatch({ type: "UPDATE_TLB_CONFIG", configdata: data })
    }
};