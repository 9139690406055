const initState = {
    environments:[],
    all_configs:[],
    isActivatedAGLVersion:false,
  };
  
  const aglConfigReducer = (state = initState, action) => {
    switch (action.type) {
        case "AGL_ENVIRONMENTS":
            return {
                ...state,
                environments: action.payload,
            };
        case "AGL_ALL_CONFIG_DATA":
          return {
              ...state,
              all_configs: action.payload,
          };
        case "IS_ACTIVATED_AGL_VERSION":
          return {
              ...state,
              isActivatedAGLVersion: true,
          };     
        default:
          return state;
      }
  
    return state;
  }
  
  export default aglConfigReducer;