const initState = {
    items: [],
    chnagedElem: '',
    validations:[],
    isMadeChanges:false,
    scrollValue: 0,
    isCollapse: false
  };
  
  const itemReducer = (state = initState, action) => {

    switch (action.type) {
      case "DO_THIS":{
        Object.values(action.rulesData.rules).map(rule => {
          if(!("td_client_hints_override" in rule.values)) {
            rule.values["td_client_hints_override"] = false;
          } else {
            rule.values = {
              ...rule.values,
              ...rule.values["td_client_hints_override"]
            }
          }
        });
        return {
          ...state,
          items: action.rulesData,
        };
      }

      case "DO_THIS_SHORTS":{
        return {
          ...state,
          items: action.rulesData,
        };
      }

      case "IS_COLLAPSE": {
        return {
          ...state,
          isCollapse: action.payload,
        }
      }
          
      case "UPDATE_ITEM":{
        let nextItems = {}
        if(action.itemType=="item"){
          Object.values(action.rulesData).map(rule => {
            if(!("td_client_hints_override" in rule.values)) {
              rule.values["td_client_hints_override"] = false;
            }
          });
          nextItems = { ...state.items, rules:action.rulesData };
        }else{
          nextItems = { ...state.items,notes:action.notes };
        }
            // state.posts is refering to the key of the rootReducer
          return { ...state, items: nextItems,isMadeChanges:action.isMadeChanges };
      }

      case "UPDATE_SHORTS_ITEM":{
        let nextItems = {}
        if(action.itemType=="item"){
          nextItems = { ...state.items, rules:action.rulesData };
        }else{
          nextItems = { ...state.items,notes:action.notes };
        }
           // state.posts is refering to the key of the rootReducer
          return { ...state, items: nextItems,isMadeChanges:action.isMadeChanges };
      
      }
      case "ADD_RULE": {
        let newItems = (Object.keys(state.items).length > 0)?[...state.items.rules]:[];
        let data = (Object.keys(state.items).length == 0) ? {
          id: 1, 
          version: action.newConfig+1, 
          status: "new",
          created_by: localStorage.getItem('email'),
          notes: 'New config',
        }: {}
        let next_rule_weight = 0;
        let platform = "default";
        let n = newItems.length
        if (n > 0) {
            next_rule_weight = newItems[n-1]['rule_weight'] + 1;
            platform = "Select Option";
        }
        const item = {
          criteria: {
            "platform": platform
          },
          values: {
            "pip_bw_initial": 400002,
            "pip_bw_max": 4000000,
            "pip_vod_prefetch": 30,
            "pip_vod_initial_buffer_mb": 6,
            "pip_vod_initial_buffer_sec": 12,
            "pip_vod_max_buffer_mb": 45,
            "pip_vod_max_buffer_sec": 90,
            "pip_live_initial_buffer": 8,
            "pip_live_target_latency": 10,
            "vod_codec": "avc1",
            "vod_profile": "std_lrl",
            "live_codec": "avc1",
            "live_profile": "std-v3",
            "td_client_hints_override": false,
          },
          rule_weight: next_rule_weight
        };
    
        newItems.push(item);
        if(Object.keys(state.items).length == 0){
          const nextData = { ...state,items:data }; // state.posts is refering to the key of the rootReducer
          const nextItems = { ...nextData.items,rules:newItems }; // state.posts is refering to the key of the rootReducer
          return { ...state, items: nextItems };

        }else{
          const nextItems = { ...state.items,rules:newItems }; // state.posts is refering to the key of the rootReducer
          return { ...state, items: nextItems,isMadeChanges:true };

        }
            
      };

      case "ADD_SHORTS_RULE": {
        let newItems = (Object.keys(state.items).length > 0)?[...state.items.rules]:[];
        let data = (Object.keys(state.items).length == 0) ? {
          id: 1, 
          version: action.newConfig+1, 
          status: "new",
          created_by: localStorage.getItem('email'),
          notes: 'New config',
        }: {}
        let next_rule_weight = 0;
        let platform = "default";
        let n = newItems.length
        if (n > 0) {
            next_rule_weight = newItems[n-1]['rule_weight'] + 1;
            platform = "Select Option";
        }
        const item = {
          criteria: {
            "platform": platform
          },
          values: {
            "profile": "baseline",
          },
          rule_weight: next_rule_weight
        };
    
        newItems.push(item);
        if(Object.keys(state.items).length == 0){
          const nextData = { ...state,items:data }; // state.posts is refering to the key of the rootReducer
          const nextItems = { ...nextData.items,rules:newItems }; // state.posts is refering to the key of the rootReducer
          return { ...state, items: nextItems };

        }else{
          const nextItems = { ...state.items,rules:newItems }; // state.posts is refering to the key of the rootReducer
          return { ...state, items: nextItems,isMadeChanges:true };

        }
            
      };

      case "CHANGED_ELEM": 
        return {
          ... state,
          chnagedElem: action.payload
        }

      case "UPDATE_SCROLL_POSITION": 
        return {
          ...state,
          scrollValue: action.payload,
      };
      case "VALIDATION_ERROR":{
        // state.posts is refering to the key of the rootReducer
        return { ...state, validations: action.validations };
      }


      default:
          return state;
      }
  
    return state;
  }
  
  export default itemReducer;