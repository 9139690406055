import {logout} from 'utils'

export const fetchUtil = async (url, options, onLoad = () => { }, onError = () => { }, parseJSON = true) => {
  if (!options) {
    options = {
      headers: {}
    };
  }
  else {
    if (!options.headers) {
      options.headers = {};
    }
  }

  options.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');

  try {
    let response = await fetch(url, options);
    let data;

    if (parseJSON) {
      data = await response.json();
    } else {
      data = response;
    }

    if (!response.ok) {
      
      console.error('API Error: ', url, '\n', response, '\n', data);

      // Unauthorized - API Authentication issues, 
      // automatically logout and send the user back to the login page.
      if (response.status === 401) { 
        logout();
        return null;      
      }
      else { 
        onError(response, data);
      }      

    } else {
      onLoad(data);
    }
  } catch (err) {
    console.error('Critical API Error: ', url, '\n', err);
    onError(err);
  }

};
