import { act } from "react";

const initState = {
  configs: [],
  all_configs: [],
  environments: null,
  config_history_data: null,
  archive_version_data: null,
  isActivatedVersion: false,
  isActivatingVersion: false,
  isDataRetrivedSuccess: false,
  activeAPPVersions: ['1.0'],
};

const appConfigReducer = (state = initState, action) => {

  switch (action.type) {
    case "CONFIG_DATA":
      return {
        ...state,
        configs: action.configData,
      };

    case "ALL_CONFIG_DATA":
      return {
        ...state,
        all_configs: action.allConfigData,
      };

    case "APPENVIRONMENTS":
      return {
        ...state,
        environments: action.payload,
      };
    case "CONFIG_HISTORY_DATA":
      return {
        ...state,
        config_history_data: action.payload,
      };
    case "ARCHIVE_VERSION_DATA":
      return {
        ...state,
        archive_version_data: action.payload,
      };
    case "IS_ACTIVATED_VERSION":
      return {
        ...state,
        isActivatedVersion: true,
      };
    case "ACTIVE_APP_VERSIONS":
      return {
        ...state,
        activeAPPVersions: action.payload,
      };

    case "COMPARE_VERSION_APP_CONFIG":
      return {
        ...state,
        storedCompareVersions: action.payload
      };

    case "IS_VERSION_ACTIVATING":
      return {
        ...state,
        isActivatingVersion: action.payload,

      };
    case "ON_DATA_SUCCESS":
      return {
        ...state,
        isDataRetrivedSuccess: action.payload,
      };

    case "ON_DATA_FAILURE":
      return {
        ...state,
        isDataRetrivedSuccess: false,
      };
    default:
      return state;
  }
}

export default appConfigReducer;