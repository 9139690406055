import React,{useState,useEffect, lazy } from "react";
import { useSelector,useDispatch } from 'react-redux';
import { Watch, PersonCircle} from "react-bootstrap-icons";
import _ from 'underscore';
import {formatDate} from 'utils';
import { getTLBShapingActiveVersions, getActiveTLBVerions, getTlbConfig, getTlbEnvironments } from 'store/actions/tlbActions';
import { toast } from 'react-toastify';
import { fetchUtil } from 'api/ApiUtils';
import ReactJson from 'react-json-view'
import EnvironmentsComponent from "components/common/EnvironmentsComponent";

const initialValues = {
  tlb_api_version: "",
  platform: "",
  asn:"",
  cluster:"",
  segment:"",
  partnerId: "",
  asset_id: ""
};


function ShapingRulesValidator(){
  const dispatch = useDispatch();
  // Get all config
  useEffect(() => {
    dispatch(getActiveTLBVerions());
  }, []);

  var configData = useSelector((state) => (state.tlbConfigs.tlbConfigs),_.isEqual);
  const activeTLBShapingVersions = useSelector((state) => state.tlbConfigs.activeTLBShapingVersions);
  const activeTLBVersions = useSelector((state) => state.tlbConfigs.activeTLBVersions);
  useEffect(()=>{
    if(activeTLBVersions?.length){
      setValues({
        ...values,
        ['tlb_api_version']: activeTLBVersions[(activeTLBVersions.length)-1],
      });
      localStorage.setItem('tlb_version',activeTLBVersions[(activeTLBVersions.length)-1]);
      dispatch(getTLBShapingActiveVersions(activeTLBVersions[(activeTLBVersions.length)-1]));
      dispatch(getTlbEnvironments(activeTLBVersions[(activeTLBVersions.length)-1]));
      dispatch(getTlbConfig(activeTLBVersions[(activeTLBVersions.length)-1]));
    }
  },[activeTLBVersions]);
  


    const platformArray = configData.filter(function(val){
      return val.name === 'platform';
    }).map(ob=>ob.meta.value);

    const segmentArray = configData.filter(function(val){
      return val.name === 'segment';
    }).map(ob=>ob.meta.value);

    const partnerArray = configData.filter(function(val){
      return val.name === 'partner_id';
    }).map(ob=>ob.meta.value);
      
  const [activeTab, setActiveTab] = useState("qa");
  const [values, setValues] = useState(initialValues);
  const [TLBAPIData, setTLBAPIData] = useState('');

  const [loader, setLoader] = useState(false);
  const environments = useSelector((state) => state.tlbConfigs.tlbEnvironments,_.isEqual);
  const InputComponent = lazy(()=> import(`components/tlbTableV1.0/InputComponent`));

  const handleClick = (env)=>{
    setActiveTab(env)
    setTLBAPIData("");
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value.trim(),
    });
  };
  const { REACT_APP_API_URL } = process.env;
  // Submit the form
  const handleSubmit = async(e) => {
    try {
      setTLBAPIData("");
      if(checkValidation()){
        setLoader(true);
        let url = `${REACT_APP_API_URL}/tlb-config/shaping/validate`;
        let data = {
            "tlb_api_version": values.tlb_api_version,
            "env": activeTab,
            "asn": values.asn,
            "cluster": values.cluster,
            "platform": values.platform,
            "segment": values.segment,
            "environment":activeTab,
            "partner_id": values.partnerId,
            "asset_id": values.asset_id
        }
        data = JSON.stringify(data)
        fetchUtil( url, { method: "POST",headers: {'Content-Type': 'application/json'}, body: data },
            (data) => {
                setTLBAPIData(data);
                setLoader(false);
            },
            () => {
                toast.error('Something went wrong!', {
                    theme: "colored"
                  });
                setLoader(false);  
            }
        );
      }else{
        toast.error('Please validate all input fields.', {
          theme: "colored"
        });
      }
    } catch (err) {
      setLoader(false);
      console.error('Critical API Error: ', '\n', err);
    }
  }

  // Validation for Input Form
  const checkValidation = () => {
      for(var key in values) {
          if(['tlb_api_version','segment', 'platform', "asset_id", "partnerId"].includes(key) && values[key] == "")
              return false;
      }
      return true;
  };

  const setTLBVersion =  (version) => {
    setValues({
      ...values,
      ['tlb_api_version']: version,
    });
    localStorage.setItem('tlb_version',version);
    setTimeout(() => {
        window.location.reload();
    }, 300);
  }

  const handleTLBVersion = (e) =>{
    setTLBVersion(e.target.value);
  }

    return (
        <>
        <div className="row">
          <div className='col-md-6'>
            <h3 className="mb-4"><strong>TLB Shaping Rules Validator API</strong></h3>
          </div>
          <div className='col-md-6'>
          <div className="row g-3 float-end">
            <div className="col-auto">
              <label htmlFor="tlb_versions" className="col-form-label">TLB Versions</label>
            </div>
            <div className="col-auto">
            {activeTLBVersions?.length? <select className="form-select" aria-label="Default select example" onChange={handleTLBVersion} value={localStorage.getItem('tlb_version')} id="tlb_api_version" name="tlb_api_version">
            {
              activeTLBVersions?.map((value,index)=>{
                return (
                    <option key={index} value={value}>{value}</option>
                )
              })
            }
          </select>:''}
            </div>
            </div>
          </div>
        </div>
        <div className="row pb-4">
          <p className="fw-bold">Routing: </p>
          <EnvironmentsComponent 
            importComponent={InputComponent}
            env={environments}
          />
        </div>
        <div className="row">
          <p className="fw-bold">Shaping: </p>
          { activeTLBShapingVersions && activeTLBShapingVersions.map((item, i) => {
            return (
              <div key={i} className="col-12 col-lg-4 mb-5 mb-lg-0">
                <div className="card shadow" onClick={()=>handleClick(item.environment)} style={{minHeight:205, cursor:'pointer',border: activeTab==item.environment ? "solid #0d6efd" : ''}}>
                  <div className="row g-0 align-items-center">
                    <div className="col-sm-12">
                      <div className="card-header border-0 bg-white text-center px-0 pe-0">
                        <h4 className="text-primary mb-0">{(item.environment).toUpperCase()}</h4>
                        <span className="d-block my-1">
                          <span className="display-6 fw-bold">
                            <span className="align-baseline font-medium"></span>{item.active_version}
                          </span>
                        </span>
                      </div>
                      <div className="card-body pb-0 pt-0">
                        <ul className="list-group list-group-flush price-list mb-2" style={{fontSize:"15px"}}>
                          <li className="list-group-item border-0 p-0">
                            <PersonCircle/> {item.created_by}
                          </li>
                          <li className="list-group-item border-0 p-0">
                          <Watch/> {formatDate(item.created_at)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="card shadow mt-4">
          <div className="card-body ">
            <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="segment" className="form-label">Segment(<span style={{color:'red'}}>*</span>)</label>
                  <select className="form-select form-control" id="segment" name="segment" aria-label="Default select example" value={values.segment} onChange={handleInputChange}>  
                      <option value="">Select Segment</option>
                      { segmentArray && segmentArray[0]?.map((item, i) => {
                        return (
                          <option key={i} value={item}>{item}</option>
                        );
                    })}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="platform" className="form-label">Platform(<span style={{color:'red'}}>*</span>)</label>
                 <select className="form-select form-control" id="platform" name="platform" aria-label="Default select example" value={values.platform} onChange={handleInputChange}>  
                      <option value="">Select platform</option>
                      { platformArray && platformArray[0]?.map((item, i) => {
                        return (
                          <option key={i} value={item}>{item}</option>
                          );
                    })}
                  </select>
                </div>
                <div className="col-md-6">
                <label htmlFor="asn" className="form-label">ASN<span style={{fontWeight:10,color:"darkgray"}}>(Optional)</span></label>
                  <input type="text" className="form-control" id="asn" onChange={handleInputChange} name="asn" value={values.asn}/>
                  
                </div>
                <div className="col-md-6">
                <label htmlFor="cluster" className="form-label">Cluster<span style={{fontWeight:10,color:"darkgray"}}>(Optional)</span></label>
                  <input type="text" className="form-control" id="cluster" onChange={handleInputChange} name="cluster" value={values.cluster}/>
                  
                </div>
                <div className="col-md-6">
                  <label htmlFor="partnerId" className="form-label">Partner Id(<span style={{color:'red'}}>*</span>)</label>
                  <select className="form-select form-control" id="partnerId" name="partnerId" aria-label="Default select example" value={values.partnerId} onChange={handleInputChange}>  
                      <option value="">Select partner id</option>
                      { partnerArray && partnerArray[0]?.map((item, i) => {
                        return (
                          <option key={i} value={item}>{item}</option>
                          );
                    })}
                  </select>
                </div>
                <div className="col-md-6">
                <label htmlFor="cluster" className="form-label">Asset ID(<span style={{color:'red'}}>*</span>)</label>
                  <input type="text" className="form-control" id="cluster" onChange={handleInputChange} name="asset_id" value={values.asset_id}/>
                  
                </div>
                <div className="col-12 text-center">
                  <button type="button" onClick={handleSubmit} className="btn btn-primary ">Simulate Request</button>
                </div>
              </form>
          </div>
        </div>
        <div className="card shadow mt-4">
          <div className="card-body">
              <h5>Result</h5>
              <div className="row">
                  <div className={`spinner-grow text-center m-auto ${loader ? '' : 'd-none'} `} role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                 { TLBAPIData && (
                    <>
                        <div className="col-md-12">
                          <ReactJson src={TLBAPIData} enableClipboard={false} iconStyle='circle' collapsed={true} displayObjectSize={false} displayDataTypes={false} name="Result"  />
                        </div>
                    </>
                  )
                  } 
              </div>
          </div>
        </div>
      </>
    )
}

export default ShapingRulesValidator;