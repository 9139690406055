const initState = {
    tlbItems: [],
    shapingItems: [],
    validations:[],
    isMadeChanges:false,
    tlbEnvironments:null,
    shapingEnvironments: null,
    activeTLBVersions:[],
    tlbConfigs: [],
    activeTLBShapingVersions:[],
    routingAlias: [],
    segmentRoute: [],
    isCollapse: false
  };
  
  const tlbReducer = (state = initState, action) => {

    switch (action.type) {
        case "GET_TLB_ITEMS":{
          return {
            ...state,
            tlbItems: action.rulesData,
          };
        };
        case "IS_COLLAPSE": {
            return {
                ...state,
                isCollapse: action.payload,
            }
        };

        case "GET_SHAPING_ITEMS":{
            const allSegment = state.tlbConfigs.find(nameItem => nameItem.name === 'segment').meta.value;
            const { rules } = action.rulesData;
            const criteriaData = [];
            const defaultValue = [];
            for(let i=0; i<=rules.length-1; i++) {
                criteriaData.push(rules[i]['criteria'].segment)
            }
            const ruleNumber = rules.findIndex(elem => elem.rule_weight === 1);
            if(!allSegment.every(elem => criteriaData.includes(elem))) {
                const missingValue = allSegment.filter(elem => !criteriaData.includes(elem) );
                if(missingValue.length > 0) {
                    missingValue.map(segment => {
                        defaultValue.push({
                            "criteria": {
                                "segment": segment
                            },
                            "values": {
                                "traffic": 100,
                                "routing_alias": ""
                            },
                            "rule_weight": 0
                        })
                    })
                    if(ruleNumber) {
                        rules.splice(ruleNumber, 0, ...defaultValue);
                    } else {
                        rules.push(defaultValue)
                    }
                }
            }

            return {
              ...state,
              shapingItems: action.rulesData,
            };
        };

        case "UPDATE_TLB_ITEM":{
            let nextItems = {}
            if(action.itemType=="item"){
            nextItems = { ...state.tlbItems, rules:action.rulesData };
            }else{
            nextItems = { ...state.tlbItems, notes:action.notes };
            }
            // state.posts is refering to the key of the rootReducer
            return { ...state, tlbItems: nextItems, isMadeChanges:action.isMadeChanges };
        };

        case "UPDATE_SHAPING_ITEM":{
            let nextItems = {}
            if(action.itemType=="item"){
            nextItems = { ...state.shapingItems, rules:action.rulesData };
            }else{
            nextItems = { ...state.shapingItems, notes:action.notes };
            }
            // state.posts is refering to the key of the rootReducer
            return { ...state, shapingItems: nextItems, isMadeChanges:action.isMadeChanges };
        };

        case "ADD_TLB_RULE": {
            let newItems = (Object.keys(state.tlbItems).length > 0)?[...state.tlbItems.rules]:[];
            let data = (Object.keys(state.tlbItems).length === 0) ? {
            id: 1, 
            tlbr_config_version: action.newConfig+1, 
            status: "new",
            created_by: localStorage.getItem('email'),
            notes: 'New config',
            }: {}
            const item = {
            criteria: {
                "segment": ""
            },
            values: [{
                "cdn": "",
                "fqdn": "",
                "routing_alias": "",
            }]
            };
        
            newItems.push(item);
            if(Object.keys(state.tlbItems).length == 0){
            const nextData = { ...state,tlbItems:data }; // state.posts is refering to the key of the rootReducer
            const nextItems = { ...nextData.tlbItems,rules:newItems }; // state.posts is refering to the key of the rootReducer
            return { ...state, tlbItems: nextItems };

            } else{
            const nextItems = { ...state.tlbItems,rules:newItems }; // state.posts is refering to the key of the rootReducer
            return { ...state, tlbItems: nextItems,isMadeChanges:true };

            }
        };

        case "ADD_SHAPING_RULE": {
            let newItems = (Object.keys(state.shapingItems).length > 0)?[...state.shapingItems.rules]: action.defaultConfig.length > 0 ? action.defaultConfig : [];
            let data = (Object.keys(state.shapingItems).length === 0) ? {
            id: 1, 
            tlbs_config_version: action.newConfig+1, 
            locked: false,
            created_by: localStorage.getItem('email'),
            notes: 'New config',
            }: {}
            let next_rule_weight = 0;
            let n = newItems.length;
            if (n > 0) {
                next_rule_weight = newItems[n-1]['rule_weight'] + 1;
            }

            const item = {
                criteria: {
                    "segment": ""
                },
                values: {
                    "traffic": 1,
                    "routing_alias": "",
                },
                rule_weight: next_rule_weight
            };
        
            if(Object.keys(action.defaultConfig).length === 0) newItems.push(item);
            if(Object.keys(state.shapingItems).length === 0) {
            const nextData = { ...state,shapingItems:data }; // state.posts is refering to the key of the rootReducer
            const nextItems = { ...nextData.shapingItems,rules:newItems }; // state.posts is refering to the key of the rootReducer
            return { ...state, shapingItems: nextItems };
            } else {
                const nextItems = { ...state.shapingItems,rules:newItems }; // state.posts is refering to the key of the rootReducer
                return { ...state, shapingItems: nextItems,isMadeChanges:true };
            }
        };

        case "SHAPING_SEGMENT_ROUTE": 
            return {
                ...state,
                segmentRoute: action.newConfig
        };

        case "TLBENVIRONMENTS":
            return {
                ...state,
                tlbEnvironments: action.payload,
        };

        case "SHAPING_ENVIRONMENTS":
            return {
                ...state,
                shapingEnvironments: action.payload,
        };

        case "CONFIG_TLB_DATA":
            return {
                ...state,
                tlbConfigs: action.configData,
        };

        case "IS_ACTIVATED_VERSION":
            return {
                ...state,
                isActivatedVersion: true,
        };

        case "ACTIVE_TLB_VERSIONS":
            return {
                ...state,
                activeTLBVersions: action.payload,
        };

        case "TLB_SHAPING_AV":
            return {
                ...state,
                activeTLBShapingVersions: action.payload,
        };

        case "TLB_ROUTING_ALIAS":
            return {
                ...state,
                routingAlias: action.payload,
        };

        case "UPDATE_TLB_CONFIG":{
            // state.posts is refering to the key of the rootReducer
        return { ...state, tlbConfigs:action.configdata };
        }

        default:
          return state;
      }
  
    return state;
  }
  
  export default tlbReducer;