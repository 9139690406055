import { combineReducers } from 'redux';
import itemReducer from './itemReducer';
import configReducer from './configReducer';
import aglConfigReducer from './aglConfigReducer';
import aglRulesReducer from './aglRulesReducer';
import tlbReducer from './tlbReducer';
import appConfigReducer from './appConfigReducer';
const rootReducer = combineReducers({
  Items: itemReducer,
  Configs: configReducer,
  AGLConfigs: aglConfigReducer,
  AGLRules: aglRulesReducer,
  tlbConfigs: tlbReducer,
  AppConfigs : appConfigReducer
});

export default rootReducer;