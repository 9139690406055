import React,{useState} from 'react';
import SidebarComponent from 'components/sidebar/SidebarComponent'
import PrivateRoutes from 'routes/PrivateRoutes'
import SessionExpireComponent from 'components/modal/SessionExpireComponent';
import Header from 'components/header/Header';
function PrivateSectionRoutes(){
    const [open,setOpen] = useState(true);

    return(
        <div>
            <div className="container-fluid" style={{padding:'0px',background:'#f5f4f4'}}>
                <div className='header'>
                <Header  open={open} setOpen={setOpen}/>
                </div>
                <div className="row conetnt-section">
                    <SidebarComponent open={open}/>
                    <div className={`content-bar ${open?'show':'notshow'}`}>
                        <div className="container-fluid mt-5"  style={{padding:'0px 10px'}}>
                         <PrivateRoutes/>
                        </div>
                    </div>
                </div>
            </div>
            <SessionExpireComponent/>
        </div>
    )
}


export default PrivateSectionRoutes;