import React, { useEffect } from "react";
import { Link,useLocation } from 'react-router-dom'
import { configAccess } from 'utils'
import { Navbar, Nav, Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import SpeedIcon from "@material-ui/icons/Speed";

function SidebarComponent({ open }) {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

  useEffect(() => {
    const getAllButton = document.querySelectorAll('.sidebar-component .accordion-button');
    for (let i = 0; i < getAllButton.length; i++) { 
      getAllButton[i].classList.add('collapsed');
      getAllButton[i].setAttribute('aria-expanded', false);
    }
    const allCollapseItem = document.querySelectorAll('.sidebar-component .accordion-collapse');
    for (let i = 0; i < allCollapseItem.length; i++) { 
      allCollapseItem[i].classList.remove('show');
    }

    const activeNode = document.querySelector('.sidebar-component .active');
    if(activeNode && !(activeNode.classList.contains('nav-link-menu'))) {
      const accordItem = document.querySelector('.sidebar-component .active').closest('.accordion-item');
      if(accordItem.closest('.accordion-collapse')) {

        const collapsedShow = accordItem.closest('.accordion-collapse');

        collapsedShow.classList.add('show');
        const collapsedShowItem = collapsedShow.closest('.accordion-item');

        collapsedShowItem.querySelector('.accordion-button').classList.remove('collapsed');
        collapsedShowItem.querySelector('.accordion-button').setAttribute('aria-expanded', true);

        const collapsedItem = collapsedShowItem.querySelectorAll('.accordion-collapse');


        for (let i = 0; i < collapsedItem.length; i++) { 
          if(collapsedItem[i].parentElement.classList.contains('main-item')){
            collapsedItem[i].classList.add('show');
          }
        }
        const allCollapse = accordItem.querySelectorAll('.accordion-collapse');
        for (let i = 0; i < allCollapse.length; i++) { 
            allCollapse[i].classList.add('show');  
        }
      }
      const allCollapse = accordItem.querySelectorAll('.accordion-collapse');

      accordItem.querySelector('.accordion-button').classList.remove('collapsed');
      accordItem.querySelector('.accordion-button').setAttribute('aria-expanded', true)
      for (let i = 0; i < allCollapse.length; i++) { 
        if(!allCollapse[i].firstChild.classList.contains('subMenu')){
          allCollapse[i].classList.add('show');
        }

      }
    }
  }, [splitLocation]);

  return (
    <>
      <div className={`d-md-block sidebar-component ${open?'show':'notshow'}`}>
      <Navbar className="align-items-start">
        <Nav className="flex-column position-fixed bg-light h-100 overflow-auto card shadow border-0" style={{width: '300px', paddingBottom: '70px'}}>
          {
            configAccess('access') &&
            <Accordion flush defaultActiveKey="td-config">
              <Accordion.Item eventKey="td-config" className="border-0 bg-transparent mb-0 main-item">
                <Accordion.Header>TD Config</Accordion.Header>
                <Accordion.Body className="p-0 ">
                  <Link to="/dashboard" className={`${splitLocation[1] === "dashboard" ?"active":""} py-2 px-4 d-block`} >Dashboard</Link>
                </Accordion.Body>
                <Accordion.Body className="p-0">
                  <Link to="/rules-validator/video-url" className={`${splitLocation[2] === "video-url" ?"active":""} py-2 px-4 d-block`}  >RV Video URL</Link>
                </Accordion.Body>
                <Accordion.Body className="p-0">
                  <Link to="/rules-validator/sc-api" className={`${splitLocation[2] === "sc-api" ?"active":""} py-2 px-4 d-block`}>RV SC api</Link>
                </Accordion.Body>
                <Accordion.Body className="py-0 px-2" >
                  <Accordion flush>
                    <Accordion.Item eventKey="shorts" className="border-0 bg-transparent mb-0">
                      <Accordion.Header className="sub-menu">Shorts</Accordion.Header>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/td/shorts-ui/dashboard" className={`${splitLocation[2] === "shorts-ui" && splitLocation[3] === 'dashboard' ?"active":""} py-2 px-4 d-block`}>Shorts Dashboard</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu"> 
                        <Link to="/td/shorts-ui/rules-validator/sc-api" className={`${splitLocation[2] === "shorts-ui" && splitLocation[3] === 'rules-validator' ?"active":""} py-2 px-4 d-block`}>Shorts RV SC api</Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          }
          {
            configAccess('agl_access') &&
            <Nav.Item>
              <Nav.Link eventKey="agl" href="/agl/dashboard" className={`${splitLocation[1] === "agl" ?"active":""} nav-link-menu`}>
                AGL Config
              </Nav.Link>
            </Nav.Item>
          }
          <Accordion flush>
            {
              (configAccess('tlb_routing_access') || configAccess('tlb_shaping_access')) && 
              <Accordion.Item eventKey="tlb-config" className="border-0 bg-transparent mb-0 main-item">
                <Accordion.Header>TLB Config</Accordion.Header>
                <Accordion.Body className="p-0">
                  <Link to="/tlb/rules-validator/app-config" className={`${splitLocation[2] === "rules-validator" ?"active":""} py-2 px-4 d-block`} >App Config</Link>
                </Accordion.Body>
                {
                  configAccess('tlb_routing_access') &&
                  <Accordion.Body className="p-0">
                    <Link to="/tlb/routing/dashboard" className={`${splitLocation[2] === "routing" ?"active":""} py-2 px-4 d-block`} >Routing</Link>
                  </Accordion.Body>
                }
                {
                  configAccess('tlb_shaping_access') && 
                  <Accordion.Body className="p-0">
                    <Link to="/tlb/shaping/dashboard" className={`${splitLocation[2] === "shaping" && splitLocation[3] === "dashboard" ?"active":""} py-2 px-4 d-block`} >Shaping</Link>
                    <Link to="/tlb/shaping/rules-validator" className={`${splitLocation[2] === "shaping" && splitLocation[3] === "rules-validator" ?"active":""} py-2 px-4 d-block`} >Rules Validator</Link>
                  </Accordion.Body>
                }
              </Accordion.Item>
            }
            {
              configAccess('app_config_access') && 
              <Accordion.Item eventKey="app-config" className="border-0 bg-transparent mb-0 main-item">
                <Accordion.Header>APP Config</Accordion.Header>
                <Accordion.Body className="py-0 px-2 ">
                  <Accordion flush>
                    <Accordion.Item eventKey="config-repo" className="border-0 bg-transparent mb-0">
                      <Accordion.Header className="sub-menu">Config Repository</Accordion.Header>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="1" to="/appconfig/initial_config/dashboard" className={`${splitLocation[2] === "initial_config" ?"active":""} py-2 px-4 d-block`} >Initial Config</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="2" to="/appconfig/base_initial_config/dashboard" className={`${splitLocation[2] === "base_initial_config" ?"active":""} py-2 px-4 d-block`} >Base Initial Config</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="3" to="/appconfig/dictionary/dashboard"  className={`${splitLocation[2] === "dictionary" ?"active":""} py-2 px-4 d-block`} >Dictionary</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="4" to="/appconfig/base_dictionary/dashboard"  className={`${splitLocation[2] === "base_dictionary" ?"active":""} py-2 px-4 d-block`} >Base Dictionary</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="5" to="/appconfig/AGL_SMART_HOOK/dashboard"  className={`${splitLocation[2] === "AGL_SMART_HOOK" ?"active":""} py-2 px-4 d-block`} >Agl Smart Hook</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="6" to="/appconfig/AGL_POPULAR_CATEGORY/dashboard"  className={`${splitLocation[2] === "AGL_POPULAR_CATEGORY" ?"active":""} py-2 px-4 d-block`} >Agl Popular Category</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="7" to="/appconfig/AGL_REPORT_ISSUE/dashboard"  className={`${splitLocation[2] === "AGL_REPORT_ISSUE" ?"active":""} py-2 px-4 d-block`} >Agl Report Issue</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="8" to="/appconfig/AGL_FEATURE_CONFIG/dashboard"  className={`${splitLocation[2] === "AGL_FEATURE_CONFIG" ?"active":""} py-2 px-4 d-block`} >Agl Feature Config</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="9" to="/appconfig/SHORTS_REPORT_ISSUE/dashboard"  className={`${splitLocation[2] === "SHORTS_REPORT_ISSUE" ?"active":""} py-2 px-4 d-block`} >Shorts Report Issue</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="10" to="/appconfig/CUSTOMERCARECONFIG/dashboard"  className={`${splitLocation[2] === "CUSTOMERCARECONFIG" ?"active":""} py-2 px-4 d-block`} >Customer Care Config</Link>
                          </Accordion.Body>
                          <Accordion.Body className="p-0 subMenu">
                            <Link key="11" to="/appconfig/AGL_BRANDING/dashboard"  className={`${splitLocation[2] === "AGL_BRANDING" ?"active":""} py-2 px-4 d-block`} >Agl Branding</Link>
                          </Accordion.Body>
                      </Accordion.Item>
                  </Accordion>
                </Accordion.Body>

                <Accordion.Body className="p-0">
                  <Link to="/appconfig/criteria" className={`${splitLocation[2] === "criteria" ?"active":""} py-2 px-4 d-block`}>Criteria Repository</Link>
                </Accordion.Body>
                <Accordion.Body className="py-0 px-2">
                  <Accordion flush>
                    <Accordion.Item eventKey="object-repo" className="border-0 bg-transparent mb-0">
                      <Accordion.Header className="sub-menu">Object Repository</Accordion.Header>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/initial_config" className={`${splitLocation[3] === "initial_config" ?"active":""} py-2 px-4 d-block`} >Initial Config</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/dictionary" className={`${splitLocation[3] === "dictionary" ?"active":""} py-2 px-4 d-block`} >Dictionary Config</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/AGL_SMART_HOOK" className={`${splitLocation[3] === "AGL_SMART_HOOK" ?"active":""} py-2 px-4 d-block`} >Agl Smart Hook</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/AGL_POPULAR_CATEGORY" className={`${splitLocation[3] === "AGL_POPULAR_CATEGORY" ?"active":""} py-2 px-4 d-block`} >Agl Popular Category</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/AGL_REPORT_ISSUE" className={`${splitLocation[3] === "AGL_REPORT_ISSUE" ?"active":""} py-2 px-4 d-block`} >Agl Report Issue</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/AGL_FEATURE_CONFIG" className={`${splitLocation[3] === "AGL_FEATURE_CONFIG" ?"active":""} py-2 px-4 d-block`} >Agl Feature Config</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/SHORTS_REPORT_ISSUE" className={`${splitLocation[3] === "SHORTS_REPORT_ISSUE" ?"active":""} py-2 px-4 d-block`} >Shorts Report Issue</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/CUSTOMERCARECONFIG" className={`${splitLocation[3] === "CUSTOMERCARECONFIG" ?"active":""} py-2 px-4 d-block`} >Customer Care Config</Link>
                      </Accordion.Body>
                      <Accordion.Body className="p-0 subMenu">
                        <Link to="/appconfig/object/AGL_BRANDING" className={`${splitLocation[3] === "AGL_BRANDING" ?"active":""} py-2 px-4 d-block`} >Agl Branding</Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            }
          </Accordion>
        </Nav>
        </Navbar>
      </div>    
    </>
    
  );
}

export default SidebarComponent;
