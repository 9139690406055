import React, { useEffect,useState } from 'react';
import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles({
    modal: {
        position: 'fixed',
        zIndex: 1,
        paddingTop: '100px',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    modalContent: {
	backgroundColor: '#fefefe',
  	margin: 'auto',
  	padding: '20px',
  	border: '1px solid #888',
  	width: '50%',
    },
    close:{
        color: '#aaaaaa',
  	  float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        '&:hover': {
      	color: '#000',
  		textDecoration: 'none',
  		cursor: 'pointer',
    	  },
        '&:focus': {
      	color: '#000',
  		textDecoration: 'none',
  		cursor: 'pointer',
    	  }
    }
});

 
function SessionExpireComponent() {
   const [open, setOpen] = useState(0);
   useEffect(() => {
	var now = new Date(); 
	var startTime = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
	var endTime = new Date(localStorage.getItem('expiration_time'));

	var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
	var resultInMinutes = Math.round(difference / 60000) - 5;
	console.log(startTime,endTime,resultInMinutes)
      const myTimeout = setTimeout(showModal, resultInMinutes*60000);
	function showModal() {
		if(resultInMinutes > 0){
  			setOpen(1);
		}
	}
    }, []);

    
    const classes = useStyles();
    return (
        <div id="myModal" className={classes.modal} style={{display:`${open==1?'block':'none'}`}}>
  		<div className={classes.modalContent}>
    			<span className={classes.close} onClick={()=>setOpen(0)}>&times;</span>
    			<h3>Your session will expire soon..</h3><br/>
                  <p>Please save your unsaved changes.</p>
  		</div>
	  </div>
    );
}


export default SessionExpireComponent;
