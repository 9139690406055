import React from 'react';
import { Clock, PersonFill, CardText, Check2Circle ,Watch,PersonCircle} from "react-bootstrap-icons";
import _ from 'underscore';
import {formatDate} from 'utils';


function EnvironmentsComponent({tdata, configVersion, importComponent, env}){
    const environments = env;
    const InputComponent = importComponent ? importComponent : null;
    let loc = window.location.href;
    let parts = [];
    let version = '';
    if (loc.indexOf('&') > 1){
        parts = loc.split('&');
        version = parts[0].split('=')[1];
    }
    else {
        parts = loc.split('=');
        version = parts[1];
    }

    return (
        <>
        { !tdata && environments && environments.map((item, i) => {
            return (
              <div key={i} className="col-12 col-lg-4 mb-5 mb-lg-0">
                <div className="card shadow" style={{minHeight:205}}>
                  <div className="row g-0 align-items-center">
                    <div className="col-sm-12">
                      <div className="card-header bg-white">
                        <h3 className="text-primary mb-0">{(item.environment).toUpperCase()}</h3>
                        <span className="d-block my-1">
                          <span className="display-6 fw-bold">
                            <span className="align-baseline font-medium"></span>{item.active_version}
                          </span>
                        </span>
                      </div>
                      <div className="card-body">
                        <ul className="list-group list-group-flush price-list mb-2" style={{fontSize:"15px"}}>
                          <li className="list-group-item border-0 p-0">
                            <PersonFill/> <span><b>Last Updated By: </b>{item.created_by}</span>
                          </li>
                          <li className="list-group-item border-0 p-0">
                          <Clock/> <span><b>Last Updated: </b>{formatDate(item.created_at)}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
        })}

        { tdata &&  (
          <div className="col-12 col-lg-5 mb-5 mb-lg-0" style={{margin:"auto"}}>
            <div className="card shadow" style={{minHeight:220}}>
              <div className="row g-0 align-items-center">

              <div className="col-sm-12">
                      <div className="card-header border-0 bg-white text-center px-0 pe-0">
                        <h4 className="text-primary mb-0">Config Version</h4>
                        <span className="d-block my-1">
                          <span className="display-6 fw-bold" data-testid="config-version">
                            <span className="align-baseline font-medium"></span>
                            { configVersion }
                          </span>
                        </span>
                      </div>
                      <div className="card-body pb-0 pt-0">
                        <ul className="list-group list-group-flush price-list mb-2" style={{fontSize:"15px"}}>
                          <li className="list-group-item border-0 p-0">
                          <PersonCircle/> {tdata.created_by}
                          </li>
                          {tdata.updated_at && 
                          <li className="list-group-item border-0 p-0">
                          <Watch/> {formatDate(tdata.updated_at)}
                          </li>
                          }
                          
                          <li className="list-group-item border-0 p-0">
                            <Check2Circle/> {
                              (tdata.status) ? tdata.status :
                              (tdata.locked === 'True')?'locked':(version === 'new')?'new':'N/A'
                            }
                          </li> 
                          <li className="list-group-item border-0 p-0 notes">
                          <CardText/>
                          i {importComponent && 
                            (("status" in tdata) && tdata.status!=='locked') ? 
                            <InputComponent
                              name="notes"
                              value={tdata.notes}
                            />
                            :(("locked" in tdata) && tdata.locked !== true)?
                            <InputComponent
                              labelOption="notes"
                              inputValue={tdata.notes}
                            />
                            : tdata.notes}
                          </li>                          
                        </ul>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        )
        }
        </>
    )
}

export default EnvironmentsComponent;