const initState = {
    rules:[],
    rules_with_keys:[],
    rules_keys:[],
  };
  
  const aglRulesReducer = (state = initState, action) => {
    switch (action.type) {
        case "AGL_RULES":
            return {
                ...state,
                rules: action.payload,
            };
        case "AGL_RULES_KEYS":
            return {
                ...state,
                rules_keys: action.payload,
            };    
        case "AGL_RULES_WITH_KEYS":
            return {
                ...state,
                rules_with_keys: action.payload,
            };    
        default:
          return state;
      }
  
    return state;
  }
  
  export default aglRulesReducer;