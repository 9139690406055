export const getUsername = () => {
    return localStorage.getItem('email');
};

export const logout = () => {
  if(sessionStorage.getItem('loggedOut')) {
    localStorage.clear();
    sessionStorage.setItem("loggedOut", true);
  } else {
    localStorage.clear();
  }
    window.location = '/login';
};


function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  
  export const formatDate = (date) => {
    var date = new Date(date);
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  };

// check user assess
export const checkAccess = (permission) => {
    const access_token = localStorage.getItem('token');
    const token = access_token
    var parsedData = JSON.parse(atob(token.split('.')[1]));
    const access = parsedData['access'];
    
    if(access && permission){
        if(access.includes('AD')){
            return true;
        }else if(access.includes('RO')){
            return false;
        }else if(access.includes(permission)){
            return true;
        }else{
            return false;
        }
    }
    return false;
}

export const configAccess = (key) => {
    const token = localStorage.getItem('token');
    if(token){
      var parsedData = JSON.parse(atob(token.split('.')[1]));
      if(parsedData && key in parsedData){
        return true;
      }
    }
    return false;
}

// check user assess
export const checkAGLAccess = (permission) => {
  const access_token = localStorage.getItem('token');
  const token = access_token
  var parsedData = JSON.parse(atob(token.split('.')[1]));
  const access = parsedData['agl_access'];
  
  if(access && permission){
      if(access.includes('AD')){
          return true;
      }else if(access.includes('RO')){
          return false;
      }else if(access.includes(permission)){
          return true;
      }else{
          return false;
      }
  }
  return false;
}

export const checkTLBAccess = (permission, platform) => {
  const access_token = localStorage.getItem('token');
  const token = access_token
  var parsedData = JSON.parse(atob(token.split('.')[1]));
  const access = parsedData[platform];
  
  if(access && permission){
      if(access.includes('AD')){
          return true;
      }else if(access.includes(permission)){
          return true;
      }else if(access.includes('RO')){
          return false;
      }else{
          return false;
      }
  }
  return false;
}

export const getNextSCVersion = (active_sc_versions) => {
  const sc_version = localStorage.getItem('sc_version');
  var nextVersion = 0;
  if(sc_version){
    const currentIndex = active_sc_versions.indexOf(sc_version);
    const nextIndex = (currentIndex + 1);
    nextVersion = active_sc_versions[nextIndex];
  }
  return nextVersion;
}

export const getNextTLBVersion = (active_tlb_versions) => {
  const tlb_version = localStorage.getItem('tlb_version');
  var nextVersion = 0;
  if(tlb_version){
    const currentIndex = active_tlb_versions.indexOf(tlb_version);
    const nextIndex = (currentIndex + 1);
    nextVersion = active_tlb_versions[nextIndex];
  }
  return nextVersion;
}

export const aglEnvs = () => {
  const envs = ["stg","dev","qa", "preprod","ppfb", "prod", "prod_canary_e", "prod_canary_f", "q4","loadtest"];
  return envs;
}


export const getNextAPPVersion = (active_app_versions) => {
  const app_config_version = localStorage.getItem('app_config_version');
  var nextVersion = 0;
  if(app_config_version){
    const currentIndex = active_app_versions.indexOf(app_config_version);
    const nextIndex = (currentIndex + 1);
    nextVersion = active_app_versions[nextIndex];
  }
  return nextVersion;
}

export const AppConfig = {'initial_config':'Initial Config','dictionary':'Dictionary','base_initial_config':'Base Initial Config','base_dictionary':'Base Dictionary' ,'AGL_SMART_HOOK': 'Agl Smart Hook','AGL_POPULAR_CATEGORY': 'Agl Popular Category', 'AGL_REPORT_ISSUE': 'Agl Report Issue', 'AGL_FEATURE_CONFIG': 'Agl Feature Config', 'SHORTS_REPORT_ISSUE': 'Shorts Report Issue', 'CUSTOMERCARECONFIG': 'Customer Care Config', 'AGL_BRANDING': 'Agl Branding'}

export const BaseAppConfig = ['base_initial_config','base_dictionary']
