import React, {useEffect} from "react";
import { fetchUtil } from '../../api/ApiUtils.js';
import logoc from '../../assets/images/logoc.png';
import logop from '../../assets/images/logop.png';
import {configAccess} from 'utils';

function CallbackComponent(){
    const { REACT_APP_API_URL } = process.env;

    useEffect(() => {
        redirectCallback();
    }, []);

    const redirectCallback = async () => {
        let url = `${REACT_APP_API_URL}/api/saml/token`;
        await fetchUtil(
            url,
            { method: 'GET' },
            (data) => {
                localStorage.setItem('loggedin', 'true');
                localStorage.setItem('email', data['email']);
                localStorage.setItem('token', data['access_token']);
                localStorage.setItem('expiration_time', data['expiration_time']);
                if(configAccess('access')){
                    setLatestSCVersion();
                }else if(configAccess('agl_access')){
                    window.location = '/agl/dashboard';
                } else if(configAccess('app_config_access')) setLatestAppVersion();

                if(configAccess('app_config_access')) localStorage.setItem('app_config_version','1.0');
            },
            () => {
                localStorage.setItem('loggedin', 'false');
            }
        );
    }

    const setLatestSCVersion = async() =>{
        await fetchUtil(
            REACT_APP_API_URL+"/app/config/active_sc_verions", { method: "GET" },
            (data) => {
               var sc_version = data?.slice(-1);
                if(sc_version)
                localStorage.setItem('sc_version',sc_version);
                window.location = '/dashboard';
            },
            () => {
                window.location = '/dashboard';
            }
        );
    }
    const setLatestAppVersion = async() =>{
        await fetchUtil(
            REACT_APP_API_URL+"/app/config/active_sc_verions", { method: "GET" },
            (data) => {
               var sc_version = data?.slice(-1);
                if(sc_version)
                localStorage.setItem('app_config_version','1.0');
                window.location = '/appconfig/initial_config/dashboard';
            },
            () => {
                window.location = '/appconfig/initial_config/dashboard';
            }
        );
    }

    return (
        <main>
            <section className='py-5 text-center container'>
                <div className='row py-lg-5'>
                    <div className='col-lg-6 col-md-8 mx-auto'>
                        <div className='card shadow' style={{ minHeight: 220 }}>
                            <div className='card-body pb-0'>
                                <div className='d-inline-flex align-items-center pb-3 mb-md-0 text-center text-decoration-none'>
                                    <img src={logoc} />
                                    <h5 className='fs-3 mx-2 mt-1'>Stream Forge</h5>
                                    <img src={logop} />
                                </div>
                                <h1 className='fw-light fs-3'>Redirecting to dashboard!</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default CallbackComponent;