import React,{useState} from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {getUsername, logout,configAccess} from 'utils';
import { Speedometer2, DoorClosed, PersonCircle,List } from "react-bootstrap-icons";
import logo from '../../assets/images/SonyLIVlogo.png';



function Header({open,setOpen}) {

  const handleLogout = () => {
    sessionStorage.setItem("loggedOut", true);
    logout();
  };
 

    return (
      <>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand style={{padding:'0 5px'}}><List width={25} height={25} onClick={()=>setOpen(!open)} style={{cursor:'pointer',display:'inline'}}/><img src={logo}  style={{display:'inline',width:'40px',marginLeft:'10px'}}/></Navbar.Brand>
          <Nav className="mr-auto">
          </Nav>
          <Nav>
            <span className="header-text">{getUsername() ? getUsername() : ''}</span>
            <PersonCircle
              width="40"
              height="40"
              className="rounded-circle"
            />
            <NavDropdown title="" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => handleLogout()}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar>
      </>
    );
}
export default Header;
