import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';
import ShapingRulesValidator from './tlb/shaping/rules-validator';

const DashboardComponent = lazy(() => import('./dashboard'));
const ShortsDashboardComponent = lazy(() => import('./dashboard/ShortsDashboardComponent'));
const RulesEditorComponent = lazy(() => import('./rules'));
const RulesEditorShortsComponent = lazy(() => import('./rules/RulesEditorShortsComponent'));
const AGLDashboardComponent = lazy(() => import('./agl/dashboard'));
const AGLRulesComponent = lazy(() => import('./agl/rules'));
const AGLRulesEditorComponent = lazy(() => import('./agl/rules/RulesEditorComponent'));
const RulesDiffComponent = lazy(() => import('./agl/rules/RulesDiff'));
const TDConfigRulesDiffComponent = lazy(() => import('./rules/RulesDiff'));
const RulesValidator = lazy(() => import('./rulesValidator/videoURL'));
const RulesValidatorSCAPI = lazy(() => import('./rulesValidator/scAPI'));
const ShortsRulesValidatorSCAPI = lazy(() => import('./rulesValidator/scAPI/ShortsRulesValidatorSCAPI'));
const ServerStatusComponent = lazy(() => import('./agl/serverStatus'));
const TLBRouteConfigRulesValidator = lazy(() => import('./tlb/routing/dashboard'));
const TLBShapeConfigRulesValidator = lazy(() => import('./tlb/shaping/dashboard'));
const TLBAppConfigRulesValidator = lazy(() => import('./tlb/appconfig/AppConfig'));
const TlbRulesDiffComponent = lazy(() => import('./tlb/routing/rules/RulesDiff'));
const TlbConfigRulesEditorComponent = lazy(() => import('./tlb/routing/newConfigRule'));
const TlbSConfigRulesEditorComponent = lazy(() => import('./tlb/shaping/newConfigRule'));
const TlbSRulesDiffComponent = lazy(() => import('./tlb/shaping/rules/RulesDiff'));
const ConfigApiStatusComponent = lazy(() => import('./agl/configApiStatus'));
const TLBConfigApiStatusComponent = lazy(() => import('./tlb/configApiStatus'));
const TDConfigApiStatusComponent = lazy(() => import('./dashboard/ConfigApiStatus'));
const AppConfigDashboardComponent = lazy(() => import('./appconfig/dashboard'));
const AppConfigRulesEditorComponent = lazy(() => import('./appconfig/rules'));
const AppConfigObjectRepoComponent = lazy(() => import('./appconfig/objectRepository/ObjectRepoComponent'));
const AppConfigCreateObject = lazy(() => import('./appconfig/objectRepository/CreateObjectComponent'));
const AppConfigApiStatusComponent = lazy(() => import('./appconfig/dashboard/ConfigApiStatus'));
const AppConfigRulesDiffComponent = lazy(() => import('./appconfig/rules/RulesDiff'));
const AppConfigCompareComponent = lazy(() => import('./appconfig/dashboard/CompareVersion'));
const AppConfigCriteriaRepoComponent = lazy(()=>import('./appconfig/criteriaRepository/CriteriaRepo'));
const AppConfigCustomCompareComponent = lazy(() => import('./appconfig/dashboard/CustomCompareVersion'));
const AppConfigBulkUpdate = lazy(() => import('./appconfig/rules/BulkUpdateComponent'));

function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route exact path={SLUGS.dashboard} component={DashboardComponent} />
                <Route exact path={SLUGS.rules_editor} component={RulesEditorComponent} />
                <Route exact path={SLUGS.shorts_dashboard} component={ShortsDashboardComponent} />
                <Route exact path={SLUGS.shorts_rules_editor} component={RulesEditorShortsComponent} />
                <Route exact path={SLUGS.shorts_rules_diff} component={TDConfigRulesDiffComponent} />
                <Route exact path={SLUGS.shorts_rules_validator_sc_api} component={ShortsRulesValidatorSCAPI} />
                <Route exact path={SLUGS.agl} component={AGLDashboardComponent} />
                <Route exact path={SLUGS.agl_rules} component={AGLRulesComponent} />
                <Route exact path={SLUGS.agl_rules_edit} component={AGLRulesEditorComponent} />
                <Route exact path={SLUGS.agl_rules_diff} component={RulesDiffComponent} />
                <Route exact path={SLUGS.rules_diff} component={TDConfigRulesDiffComponent} />
                <Route exact path={SLUGS.rules_validator} component={RulesValidator} />
                <Route exact path={SLUGS.rules_validator_sc_api} component={RulesValidatorSCAPI} />
                <Route exact path={SLUGS.agl_server_status} component={ServerStatusComponent} />
                <Route exact path={SLUGS.tlb_route_config} component={TLBRouteConfigRulesValidator} />
                <Route exact path={SLUGS.tlb_shape_config} component={TLBShapeConfigRulesValidator} />
                <Route exact path={SLUGS.tlb_app_config} component={TLBAppConfigRulesValidator} />
                <Route exact path={SLUGS.tlb_rules_diff} component={TlbRulesDiffComponent} />
                <Route exact path={SLUGS.tlb_shaping_rules_diff} component={TlbSRulesDiffComponent} />
                <Route exact path={SLUGS.tlb_rules_editor} component={TlbConfigRulesEditorComponent} />
                <Route exact path={SLUGS.tlbs_rules_editor} component={TlbSConfigRulesEditorComponent} />
                <Route exact path={SLUGS.tlb_shape_rv} component={ShapingRulesValidator} />
                <Route exact path={SLUGS.config_api_status} component={ConfigApiStatusComponent} />
                <Route exact path={SLUGS.routing_tlb_api_status} component={TLBConfigApiStatusComponent} />
                <Route exact path={SLUGS.shaping_tlb_api_status} component={TLBConfigApiStatusComponent} />
                <Route exact path={SLUGS.td_config_api_status} component={TDConfigApiStatusComponent} />

                <Route exact key='inital_config' path={SLUGS.appconfig} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.app_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.app_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.app_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.app_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route path="/appconfig/object/:configType/create" component={AppConfigCreateObject} />
                <Route path="/appconfig/object/:configType" component={AppConfigObjectRepoComponent} />

                <Route exact key='dictionary' path={SLUGS.dictionary} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.dictionary_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.dictionary_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.dictionary_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.dictionary_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact path={SLUGS.shorts_config_api_status} component={TDConfigApiStatusComponent} />

                <Route exact path={SLUGS.criteria_object_repo} component={AppConfigCriteriaRepoComponent} />

                <Route path="/appconfig/:configType/compare-version" component={AppConfigCompareComponent} />

                <Route exact key='base_inital_config' path={SLUGS.base_appconfig} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.base_app_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.base_app_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.base_app_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.base_app_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact key='base_dictionary' path={SLUGS.base_dictionary} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.base_dictionary_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.base_dictionary_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.base_dictionary_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.base_dictionary_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact key='AGL_SMART_HOOK' path={SLUGS.AGL_SMART_HOOK} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.AGL_SMART_HOOK_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.AGL_SMART_HOOK_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.AGL_SMART_HOOK_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.AGL_SMART_HOOK_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact key='AGL_POPULAR_CATEGORY' path={SLUGS.AGL_POPULAR_CATEGORY} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.AGL_POPULAR_CATEGORY_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.AGL_POPULAR_CATEGORY_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.AGL_POPULAR_CATEGORY_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.AGL_POPULAR_CATEGORY_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact key='AGL_REPORT_ISSUE' path={SLUGS.AGL_REPORT_ISSUE} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.AGL_REPORT_ISSUE_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.AGL_REPORT_ISSUE_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.AGL_REPORT_ISSUE_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.AGL_REPORT_ISSUE_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact key='AGL_FEATURE_CONFIG' path={SLUGS.AGL_FEATURE_CONFIG} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.AGL_FEATURE_CONFIG_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.AGL_FEATURE_CONFIG_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.AGL_FEATURE_CONFIG_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.AGL_FEATURE_CONFIG_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact key='SHORTS_REPORT_ISSUE' path={SLUGS.SHORTS_REPORT_ISSUE} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.SHORTS_REPORT_ISSUE_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.SHORTS_REPORT_ISSUE_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.SHORTS_REPORT_ISSUE_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.SHORTS_REPORT_ISSUE_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact key='CUSTOMERCARECONFIG' path={SLUGS.CUSTOMERCARECONFIG} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.CUSTOMERCARECONFIG_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.CUSTOMERCARECONFIG_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.CUSTOMERCARECONFIG_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.CUSTOMERCARECONFIG_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route exact key='AGL_BRANDING' path={SLUGS.AGL_BRANDING} component={AppConfigDashboardComponent} />
                <Route exact path={SLUGS.AGL_BRANDING_config_rules_diff} component={AppConfigRulesDiffComponent} />
                <Route exact path={SLUGS.AGL_BRANDING_config_api_status} component={AppConfigApiStatusComponent} />
                <Route exact path={SLUGS.AGL_BRANDING_config_rules_editor} component={AppConfigRulesEditorComponent} />
                <Route exact path={SLUGS.AGL_BRANDING_config_rules_bulk_update} component={AppConfigBulkUpdate} />

                <Route path="/appconfig/:configType/compare-version2" component={AppConfigCustomCompareComponent} />

                <Redirect to={SLUGS.dashboard} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
