import React, { useState } from 'react';
import logoc from '../../assets/images/logoc.png';
import logop from '../../assets/images/logop.png';

const { REACT_APP_API_URL } = process.env;

function LoginComponent() {
    const [isRedirecting, setisRedirecting] = useState(false);
    const [title, setTitle] = useState('Welcome to Config Manager!');
    const handleClick = () => {
        setisRedirecting(true);
        setTitle('Redirecting to AWS SSO login page...');
        let url = `${REACT_APP_API_URL}/api/saml/login`;
        window.location = url;
        localStorage.removeItem('loggedOut');
    };
    return (
        <main>
            <section className='py-5 text-center container'>
                <div className='row py-lg-5'>
                    <div className='col-lg-6 col-md-8 mx-auto'>
                        <div className='card shadow' style={{ minHeight: 220 }}>
                            <div className='card-body pb-0'>
                                <div className='d-inline-flex align-items-center pb-3 mb-md-0 text-center text-decoration-none'>
                                    <img src={logoc} />
                                    <h5 className='fs-3 mx-2 mt-1'>Sony Liv</h5>
                                    <img src={logop} />
                                </div>
                                <h1 className='fw-light fs-3'>{title}</h1>
                                <p>
                                    <button
                                        onClick={sessionStorage.getItem('loggedOut') ? handleClick : handleClick()}
                                        className={`btn btn-primary my-2 mt-4 ${isRedirecting ? "d-none" : ""}`}>
                                        Click here to Login
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default LoginComponent;
