const initState = {
    configs: [],
    all_configs:[],
    environments:null,
    config_history_data:null,
    archive_version_data:null,
    isActivatedVersion:false,
    activeSCVersions:[],
  };
  
  const configReducer = (state = initState, action) => {

    switch (action.type) {
        case "CONFIG_DATA":
          return {
            ...state,
            configs: action.configData,
          };

        case "ALL_CONFIG_DATA":
          return {
            ...state,
            all_configs: action.allConfigData,
          };

        case "ENVIRONMENTS":
            return {
                ...state,
                environments: action.payload,
            }; 
        case "CONFIG_HISTORY_DATA":
            return {
                ...state,
                config_history_data: action.payload,
            };    
        case "ARCHIVE_VERSION_DATA":
            return {
                ...state,
                archive_version_data: action.payload,
            };    
        case "IS_ACTIVATED_VERSION":
              return {
                  ...state,
                  isActivatedVersion: true,
          };    
        case "ACTIVE_SC_VERSIONS":
          return {
              ...state,
              activeSCVersions: action.payload,
          };   
        default:
          return state;
      }
  
    return state;
  }
  
  export default configReducer;